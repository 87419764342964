<template>
  <div>
    <!-- if this is active route (e.g. /users) -->
    <template v-if="isCurrentRoute">
      <h2>{{ $t('views.projects.projectaccountingtasks.title') }}</h2>

      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <!-- <b-button variant="outline-primary" @click="navigate('Users.Addresses.Add')"><b-icon icon="plus"/> {{ $t('views.users.addresses.button.addaddress') }}</b-button> -->
        </b-col>
      </b-row>

     <b-table striped hover :items="this.projectaccountingtasks" :fields="fields">
        <!-- <template v-slot:head(importToBexio)>
          <input type="checkbox" v-model="isSelectedFilter">
        </template> -->
        <!-- <template v-slot:cell(sentToBexioAt)="data">
          <timeago :datetime="data.item.sentToBexioAt" auto-update/>
        </template> -->
        <template v-slot:cell(start)="data">
          <timeago :datetime="data.item.start" auto-update/>
          <!-- <b-form-input v-model="data.item.start" @blur="updateProjectAccountingTask(data.item)" /> -->
        </template>
         <template v-slot:cell(importToBexio)="data">
          <b-form-checkbox v-model="data.item.importToBexio" @change="updateProjectAccountingTask(data.item)" />
        </template>
        <template v-slot:cell(isIgnoredForAccounting)="data">
          <b-form-checkbox v-model="data.item.isIgnoredForAccounting" @change="updateProjectAccountingTask(data.item)" />
        </template>
        <template v-slot:cell(hourlyRate)="data">
          <b-form-input v-model="data.item.hourlyRate" @blur="updateProjectAccountingTask(data.item)" />
        </template>
        <template v-slot:cell(totalHoursBudget)="data">
          <b-form-input v-model="data.item.totalHoursBudget" @blur="updateProjectAccountingTask(data.item)" />
        </template>
        <template v-slot:cell(taskName)="data">
          <b-form-input v-model="data.item.taskName" @blur="updateProjectAccountingTask(data.item)" />
        </template>
        <template v-slot:cell(timeSpendInMinutes)="data">
          <small>{{ formatMinutesToHoursMinutes(data.item.timeSpendInMinutes)}}</small>
          <b-form-input v-model="data.item.timeSpendInMinutes" @blur="updateProjectAccountingTask(data.item)" />
        </template>
          </b-table>
    </template>

    <!-- if subroute is active route (e.g. /accountingtasks/detail) -->
    <section v-else>
      <router-view/>
    </section>
  </div>
</template>

<script>
import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
import http from '@/$plugins/http'

const ENTITY_KEY = 'projectaccountingtasks'
const RELATION_KEY = 'accountingtasks'

export default {
  name: 'Projects.AccountingTasks',
  mixins: [MixinEntityBase],
  components: {
    // EntityEntryTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      loading: false,
      projectaccountingtasks: {}, // Store accounting task names by ID
      fields: [
        { key: 'accountingTaskName', label: 'Abrechnungstask', sortable: true },
        { key: 'totalHoursBudget', label: 'Verkaufter Aufwand (in Stunden)', class: 'text-right', sortable: true },
        { key: 'hourlyRate', label: 'Stundensatz (in CHF)', class: 'text-right', sortable: true },
        {
          key: 'totalAmount',
          label: 'Gesamtbetrag',
          class: 'text-right',
          sortable: true,
          formatter: (value, key, item) => {
            const hourlyRate = parseFloat(item.hourlyRate) || 0
            const totalHoursBudget = parseFloat(item.totalHoursBudget) || 0
            return this.formatMoney(hourlyRate * totalHoursBudget)
          }
        }
      ]
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    }
  },
  methods: {
    formatNumber (number) {
      if (number === null || number === undefined) return
      return number.toLocaleString('de-CH', { minimumFractionDigits: 2 })
    },
    formatMoney (amount) {
      return parseFloat(amount).toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF'
      })
    },
    async updateProjectAccountingTask (projectAccountingTask) {
      try {
        const endpoint = `/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectaccountingtasks}/${projectAccountingTask.id}/updatepartial`
        const payload = {
          hourlyRate: projectAccountingTask.hourlyRate,
          totalHoursBudget: projectAccountingTask.totalHoursBudget
        }
        await http.put(endpoint, payload)
        // Handle the response or perform additional actions if needed
      } catch (error) {
        console.error(error)
      }
    },
    async loadProjectAccountingTasks () {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectaccountingtasks}/fromproject/${this.projectId}/all`)
        const responseAccountingTask = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.accountingtasks}/all`)
        this.projectaccountingtasks = response.data.result
        this.accountingtasks = responseAccountingTask.data.result
        this.projectaccountingtasks.forEach((task) => {
          const accountingTask = responseAccountingTask.data.result.find((item) => item.id === task.accountingTaskId)
          if (accountingTask) {
            task.accountingTaskName = accountingTask.name
          }
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    const urlParts = window.location.pathname.split('/')
    const projectId = urlParts[urlParts.length - 2] // Extract the second-to-last part from the URL path
    this.projectId = projectId
    this.loadProjectAccountingTasks()
    TableHelper.rowClassHook(entity => entity.$routerLink ? 'has-routerlink' : null)
  }
}
</script>

<style lang="scss"></style>
