<template>
  <div>
    <h2>An Bexio gesendet</h2>
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <b-row class="mb-3" align-h="between">
      <b-col cols="auto"></b-col>
      <b-col cols="auto">
      </b-col>
    </b-row>
    <div>
        <p>Nachfolgende Einträge wurden an Bexio gesendet</p>
        <b-table striped hover :items="this.reports" :fields="fields">
         <template v-slot:cell(revertButton)="data">
  <b-button @click="updateWorklog(data.item)">Abgleich Rückgängig machen</b-button>
</template>
            <template v-slot:cell(start)="data">
              <timeago :datetime="data.item.start" auto-update />
            </template>
             <template v-slot:cell(sentToBexioAt)="data">
              <timeago :datetime="data.item.sentToBexioAt" auto-update />
            </template>
            <template v-slot:cell(hoursPrepared)="data">
              {{ data.item.hoursPrepared }}<br /><small> ({{ formatHoursToDays(data.item.hoursPrepared) }})</small>
            </template>
            <template v-slot:cell(projectAccountingTaskId)="data">
              <b-form-select v-model="data.item.projectAccountingTaskId" :options="accountingTaskOptions" disabled />
            </template>
          </b-table>
    </div>
  </div>
</template>
<script>
import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

// import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
// import EntityEntryTable from '@/components/entity/EntryTable'
import http from '@/$plugins/http'

const ENTITY_KEY = 'projectaccountingtasks'
const RELATION_KEY = 'accountingtasks'
const WORKLOGS_NOT_EDITABLE = 'Nicht zugeordnet'

export default {
  name: 'Projects.SentToBexio',
  mixins: [MixinEntityBase],
  components: {
    // EntityEntryTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      worklogsNotEditable: WORKLOGS_NOT_EDITABLE,
      hasCostCeiling: false,
      projectaccountingtasks: [],
      accountingtasks: [],
      loading: false,
      reports: [],
      emailFilter: '',
      accountableFilter: false,
      dateFilter: null,
      accountingTaskFilter: '',
      isSelectedFilter: false,
      sendingToBexio: false,
      fields: [
        { key: 'revertButton', label: 'Abgleich rückgängig machen' }, // Add this line
        { key: 'start', label: 'Datum', sortable: true },
        { key: 'projectAccountingTaskId', label: 'Abrechnungs-Task', sortable: true },
        { key: 'username', label: 'Benutzer', sortable: true },
        { key: 'clickUpCustomTaskId', label: 'Task-Id', sortable: true },
        { key: 'taskName', label: 'Task-Name', sortable: true },
        { key: 'comment', label: 'Kommentar', sortable: true },
        { key: 'industryTimeSpend', label: 'Zeitaufwand', sortable: true }
      ]
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find((c) => c.name === this.$options.name) !== undefined
    },
    totalHoursPrepared () {
      let total = 0
      this.summaryreports.withCostCeiling.forEach((item) => {
        total += item.hoursPrepared
      })
      this.summaryreports.withoutCostCeiling.forEach((item) => {
        total += item.hoursPrepared
      })
      return total
    },
    totalMoneyPrepared () {
      let total = 0
      this.summaryreports.withCostCeiling.forEach((item) => {
        total += item.moneyPrepared
      })
      this.summaryreports.withoutCostCeiling.forEach((item) => {
        total += item.moneyPrepared
      })
      return total
    },
    accountingTaskOptions () {
      return this.projectaccountingtasks
        .map((task) => ({
          text: task.accountingTask.name,
          value: task.id
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    }
  },
  methods: {
    canBePrepared (entry) {
      return entry.accountingTask.name !== this.worklogsNotEditable
    },
    worklogTaskCanBeChanged (entry) {
      return entry.accountingTask.name !== this.worklogsNotEditable
    },
    formatMinutesToHoursMinutes (minutes) {
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60
      return `${hours}h ${remainingMinutes}m`
    },
    formatHoursToDays (value) {
      const days = Math.floor(value / 24)
      const hours = value % 24
      return `${days}d ${hours}h`
    },
    formatNumber (number) {
      if (number === null || number === undefined) return
      return number.toLocaleString('de-CH', { minimumFractionDigits: 2 })
    },
    formatMoney (amount) {
      return parseFloat(amount).toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF'
      })
    },
    filteredWorklogs (worklogs, value) {
      return worklogs.filter((worklog) => worklog.isIgnoredForCostCeiling === value)
    },
    selectAccountingTask (projectAccountingTaskId) {
      const selectedAccountingTaskId = this.projectaccountingtasks.filter((at) => at.id === projectAccountingTaskId)[0].accountingTaskId
      return selectedAccountingTaskId
    },
    async updateWorklog (worklog) {
      try {
        const endpoint = `/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.worklogs}/${worklog.id}/updatepartial`
        const payload = {
          sentToBexioAt: null
        }
        await http.put(endpoint, payload)
        // Handle the response or perform additional actions if needed
      } catch (error) {
        console.error(error)
      }
    },
    async loadWorklogs () {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.worklogs}/fromproject/${this.projectId}/senttobexio`)
        const projectaccountingtaskResponse = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectaccountingtasks}/fromproject/${this.projectId}/all`)
        this.projectaccountingtasks = projectaccountingtaskResponse.data.result
        const accountingtaskResponse = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.accountingtasks}/all`)
        this.accountingtasks = accountingtaskResponse.data.result

        this.projectaccountingtasks.forEach(entry => {
          entry.accountingTask = this.accountingtasks.filter(at => at.id === entry.accountingTaskId)[0]
        })

        this.reports = response.data
        this.reports.forEach(entry => {
          const hours = Math.floor(entry.timeSpendInMinutes / 60)
          const minutes = entry.timeSpendInMinutes % 60
          entry.industryTimeSpend = hours + minutes / 60
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    setReadyToUpload (worklog, value) {
      worklog.isReadyToUpload = value
      // Here, you would probably also want to send a request to your API to update the worklog item on the server
    },
    async sendToBexio () {
      try {
        this.sendingToBexio = true
        await http.put(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.worklogs}/fromproject/${this.projectId}/sendtobexio`) // Replace with the actual endpoint
        // Handle the successful response if needed
      } catch (error) {
        console.error(error)
        // Handle the error if needed
      } finally {
        this.sendingToBexio = false
        this.sentToBexio = true
      }
    }
  },
  created () {
    const urlParams = new URLSearchParams(window.location.search)
    this.currentPage = parseInt(urlParams.get('page')) || 1
    this.pageSize = parseInt(urlParams.get('pageSize')) || 50
    const urlParts = window.location.pathname.split('/')
    const projectId = urlParts[urlParts.length - 2] // Extract the second-to-last part from the URL path
    this.projectId = projectId
    this.loadWorklogs()
  }
}
</script>
<style lang="scss">
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}
.fw-bold {
  font-weight: bold;
  font-size: 1rem;
}
.summaryrow {
  font-size: 1rem;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
