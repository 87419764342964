<template>
  <div>
    <!-- if this is active route (e.g. /users) -->
    <template v-if="isCurrentRoute">
      <h2>Reports</h2>
      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <b-button variant="outline-warning" @click="LoadInternalExcelReport()"> {{ $t('views.projects.report.button.excelexporttitle') }}</b-button><span>&nbsp;</span>
          <b-button variant="outline-primary" class="bigbutton" @click="LoadExcelReport(true)"> {{ $t('views.projects.report.button.excelexportforcustomerstitle') }}</b-button>
        </b-col>
      </b-row>
<div v-if="this.hasCostCeiling">
  <h1>Projekt mit Kostendach</h1>
  <h3>Angaben zum Kostendach</h3>
  <div>
  <b-row align-h="between">
    <b-col cols="auto">
      <ol class="list-group list-group-numbered">
        <template v-if="this.hasCostCeiling">
          <li class="bg-secondary list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Restbudgets</div>
                  </div>
                </li>
         <li v-if="this.report.totalCostCeiling.resumingInHours > 0" class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-4 me-auto">
            <div class="fw-bold">in Stunden</div>
             Diese Stunden sind unter dem Kostendach noch abrechenbar
          </div>
          <span class="badge bg-primary summaryrow">{{formatNumber(this.report.totalCostCeiling.resumingInHours)}}</span>
        </li>
<li v-if="this.report.totalCostCeiling.resumingInMoney > 0" class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-4 me-auto">
            <div class="fw-bold">in CHF</div>
             Diese Stunden sind unter dem Kostendach noch abrechenbar
          </div>
          <span class="badge bg-primary summaryrow">{{formatMoney(this.report.totalCostCeiling.resumingInMoney)}}</span>
        </li>
<li class="bg-secondary list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Gesamtbudget</div>
                  </div>
                </li>
          <li v-if="this.report.totalCostCeiling.budgetInHours > 0" class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-4 me-auto">
            <div class="fw-bold">in Stunden</div>
            Das Gesamtbudget dieses Kostendachs
          </div>
          <span class="badge bg-warning summaryrow">{{formatNumber(this.report.totalCostCeiling.budgetInHours)}}</span>
        </li>
        <li v-if="this.report.totalCostCeiling.budgetInMoney > 0" class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-4 me-auto">
            <div class="fw-bold">in CHF</div>
            Das Gesamtbudget dieses Kostendachs in CHF
          </div>
          <span class="badge bg-warning summaryrow">{{formatMoney(this.report.totalCostCeiling.budgetInMoney)}}</span>
        </li>
        <li class="bg-secondary list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Abrechnung</div>
                  </div>
                </li>
        <li v-if="this.report.totalCostCeiling.invoicedInHours > 0" class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-4 me-auto">
            <div class="fw-bold">in Stunden</div>
            Bereits abgerechnete Leistungen in Stunden
          </div>
          <span class="badge bg-secondary summaryrow">{{formatNumber(this.report.totalCostCeiling.invoicedInHours)}}</span>
        </li>
        <li v-if="this.report.totalCostCeiling.invoicedInMoney > 0" class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-4 me-auto">
            <div class="fw-bold">in CHF</div>
            Bereits abgerechnete Leistungen in Stunden
          </div>
          <span class="badge bg-secondary summaryrow">{{formatMoney(this.report.totalCostCeiling.invoicedInMoney)}}</span>
        </li>
        </template>
      </ol>
    </b-col>
  </b-row>
  </div>
</div>
<div v-else>
  <h1>Projekt ohne Kostendach</h1>
</div>
<div>
</div>

<br />
<div v-if="report.forInternal.some(item => item.totalReported > 0)">
<h3 v-if="this.hasCostCeiling">Stundenübersicht (Kostendach)</h3>
<h3 v-else>Leistungsübersicht</h3>
<p v-if="this.hasCostCeiling">Diese Leistungen sind Teil von einem vereinbartem Kostendach</p>
    <b-table striped hover :items="withCostCeilings" :fields="fields">
      <template v-slot:cell(offered)="data">
        {{ formatNumber(data.item.offered)}}
      </template>
       <template v-slot:cell(totalReported)="data">
        {{ formatNumber(data.item.totalReported)}}
      </template>
      <template v-slot:cell(accounted)="data">
        {{ formatNumber(data.item.accounted)}}
      </template>
      <template v-slot:cell(remainingFromBudget)="data">
        {{ formatNumber(data.item.remainingFromBudget)}}
      </template>
      <template v-slot:cell(toBePrepared)="data">
        {{ formatNumber(data.item.toBePrepared)}}
      </template>
      <template v-slot:cell(accountable)="data">
        {{ formatNumber(data.item.accountable)}}
      </template>
      <template v-slot:cell(readyForImport)="data">
        {{ formatNumber(data.item.readyForImport)}}
      </template>
       <template v-slot:cell(readyForImportInMoney)="data">
        {{ formatMoney(data.item.readyForImportInMoney)}}
      </template>
      <template v-slot:cell(summary)="data">
        <div :class="extractSummaryClass(data.item.summary)">{{ formatNumber(data.item.summary)}}</div>
      </template>
    </b-table>
<br />
</div>
<div v-if="withLimitless.some(item => item.totalReported > 0)">
<h3>Stundenübersicht (Nach Aufwand, nicht Teil von Kostendach)</h3>
<p>Diese Leistungen sind NICHT Teil von einem vereinbartem Kostendach</p>
    <b-table striped hover :items="withLimitless" :fields="fieldsWithoutCostCeiling">
      <template v-slot:cell(offered)="data">
        {{ formatNumber(data.item.offered)}}
      </template>
       <template v-slot:cell(totalReported)="data">
        {{ formatNumber(data.item.totalReported)}}
      </template>
      <template v-slot:cell(accounted)="data">
        {{ formatNumber(data.item.accounted)}}
      </template>
      <template v-slot:cell(remainingFromBudget)="data">
        {{ formatNumber(data.item.remainingFromBudget)}}
      </template>
      <template v-slot:cell(toBePrepared)="data">
        {{ formatNumber(data.item.toBePrepared)}}
      </template>
      <template v-slot:cell(accountable)="data">
        {{ formatNumber(data.item.accountable)}}
      </template>
      <template v-slot:cell(readyForImport)="data">
        {{ formatNumber(data.item.readyForImport)}}
      </template>
       <template v-slot:cell(readyForImportInMoney)="data">
        {{ formatMoney(data.item.readyForImportInMoney)}}
      </template>
      <template v-slot:cell(summary)="data">
        <div :class="extractSummaryClass(data.item.summary)">{{ formatNumber(data.item.summary)}}</div>
      </template>
    </b-table>
<br />
</div>
<h2>Kundensicht</h2>
<p style="color:red"><b>WICHTIG:</b> Enthält auch die Leistungen die aktuell im Stadium "Bereit für Import in Bexio" sind</p>
<div v-if="this.hasCostCeiling">
<h3>Kostendach (geleistet in Stunden)</h3>
    <b-table striped hover :items="costceilings" :fields="customerviewfields">
      <template v-slot:cell(budgetInHours)="data">
        {{ formatNumber(data.item.budgetInHours)}}
      </template>
       <template v-slot:cell(invoicedInHours)="data">
        {{ formatNumber(data.item.invoicedInHours)}}
      </template>
      <template v-slot:cell(resumingInHours)="data">
        {{ formatNumber(data.item.resumingInHours)}}
      </template>
    </b-table>

    <h3>Kostendach (geleistet in CHF)</h3>
    <b-table striped hover :items="costceilings" :fields="costceilingfields">
      <template v-slot:cell(budgetInMoney)="data">
        {{ formatMoney(data.item.budgetInMoney)}}
      </template>
       <template v-slot:cell(invoicedInMoney)="data">
        {{ formatMoney(data.item.invoicedInMoney)}}
      </template>
      <template v-slot:cell(resumingInMoney)="data">
        {{ formatMoney(data.item.resumingInMoney)}}
      </template>
    </b-table>
</div>
    <h3 v-if="this.hasCostCeiling">Leistungen (nach Aufwand, nicht Teil von Kostendach)</h3>
    <h3 v-else>Leistungen</h3>
     <b-table striped hover :items="withoutcostceilings" :fields="withoutcostceilingfields">
       <template v-slot:cell(invoicedInHours)="data">
        {{ formatNumber(data.item.invoicedInHours)}}
      </template>
      <template v-slot:cell(invoicedInMoney)="data">
        {{ formatMoney(data.item.invoicedInMoney)}}
      </template>
    </b-table>

    </template>

    <!-- if subroute is active route (e.g. /accountingtasks/detail) -->
    <section v-else>
      <router-view/>
    </section>
  </div>
</template>

<script>
import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'
import MixinEntityBase from '@/components/mixins/EntityBase'
// import { TableHelper } from '@/assets/js/helper/table'

// import MixinEntityBase from '@/components/mixins/EntityBase'
// import EntityEntryTable from '@/components/entity/EntryTable'
import http from '@/$plugins/http'

const ENTITY_KEY = 'project'

export default {
  name: 'Projects.Worklogs',
  mixins: [MixinEntityBase],
  components: {
    // EntityEntryTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      loading: false,
      withCostCeilings: [],
      withLimitless: [],
      report: [],
      reportsWithoutCostceiling: [],
      remainingBudgetInHours: 0,
      remainingBudgetInMoney: 0,
      costceilings: [],
      withoutcostceilings: [],
      hasCostCeiling: false,
      projectId: '',
      fields: [
        { key: 'projectAccountingTask', label: 'Abrechnungstask', sortable: true },
        { key: 'offered', label: 'Offeriert', class: 'text-right', sortable: true },
        { key: 'totalReported', label: 'Rapportiert', class: 'text-right', sortable: true },
        { key: 'accounted', label: 'Verrechnet', class: 'text-right', sortable: true },
        { key: 'remainingFromBudget', label: 'Restbudget', class: 'text-right', sortable: true },
        { key: 'toBePrepared', label: 'Offen', class: 'text-right', sortable: true },
        { key: 'accountable', label: 'Abrechenbar', class: 'text-right', tdClass: this.extractToPrepareClass, sortable: true },
        { key: 'readyForImport', label: 'Bereit für Import in Bexio (in Stunden)', class: 'text-right', sortable: true },
        { key: 'readyForImportInMoney', label: 'Bereit für Import in Bexio (in CHF)', class: 'text-right', sortable: true },
        { key: 'overBudget', label: 'Überschossen', class: 'text-right', sortable: true }
      ],
      fieldsWithoutCostCeiling: [
        { key: 'projectAccountingTask', label: 'Abrechnungstask', sortable: true },
        { key: 'totalReported', label: 'Rapportiert', class: 'text-right', sortable: true },
        { key: 'accounted', label: 'Verrechnet', class: 'text-right', sortable: true },
        { key: 'toBePrepared', label: 'Offen', class: 'text-right', tdClass: this.extractToPrepareClass, sortable: true },
        { key: 'readyForImport', label: 'Bereit für Import in Bexio', class: 'text-right', sortable: true },
        { key: 'readyForImportInMoney', label: 'Bereit für Import in Bexio (in CHF)', class: 'text-right', sortable: true }
      ],
      customerviewfields: [
        { key: 'projectAccountingTask', label: 'Abrechnungstask', sortable: true },
        { key: 'budgetInHours', label: 'Geplante Stunden', class: 'text-right', sortable: true },
        { key: 'invoicedInHours', label: 'Abgerechnete Stunden', class: 'text-right', sortable: true },
        { key: 'resumingInHours', label: 'Restbudget', class: 'text-right', sortable: true }
      ],
      costceilingfields: [
        { key: 'projectAccountingTask', label: 'Abrechnungstask', sortable: true },
        { key: 'budgetInMoney', label: 'Kostendach', class: 'text-right', sortable: true },
        { key: 'invoicedInMoney', label: 'Aktuell verrechnet', class: 'text-right', sortable: true },
        { key: 'resumingInMoney', label: 'Restbudget', class: 'text-right', sortable: true }
      ],
      withoutcostceilingfields: [
        { key: 'projectAccountingTask', label: 'Abrechnungstask', sortable: true },
        { key: 'invoicedInHours', label: 'in Stunden', class: 'text-right', sortable: true },
        { key: 'invoicedInMoney', label: 'in CHF', class: 'text-right', sortable: true }
      ]

    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    }
  },
  methods: {
    formatNumber (number) {
      if (number === null || number === undefined) return
      return number.toLocaleString('de-CH', { minimumFractionDigits: 2 })
    },
    formatMoney (amount) {
      return parseFloat(amount).toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF'
      })
    },
    extractSummaryClass (number) {
      if (number > 0) {
        return 'text-danger'
      }
    },
    extractToPrepareClass (number) {
      if (number > 0) {
        return 'bg-info'
      }
    },
    async loadReport () {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectreporting}/fromproject/${this.projectId}`)
        this.report = response.data
        this.reportsWithoutCostceiling = response.data.forInternalWithoutCostCeilings
        this.costceilings = response.data.costCeilingSummary
        this.withoutcostceilings = response.data.withoutCostCeilingSummary
        this.resumingInHours = response.data.totalCostCeiling.resumingInHours
        this.resumingInMoney = response.data.totalCostCeiling.resumingInMoney
        this.hasCostCeiling = response.data.hasCostCeiling

        response.data.forInternal.forEach(entry => {
          if (entry.costCeiling !== undefined) {
            var toPush = entry.costCeiling
            toPush.projectAccountingTask = entry.projectAccountingTask
            this.withCostCeilings.push(toPush)
          }

          if (entry.limitless !== undefined) {
            var toPushLimitless = entry.limitless
            toPushLimitless.projectAccountingTask = entry.projectAccountingTask
            this.withLimitless.push(toPushLimitless)
          }
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async LoadExcelReport (loadFullReport) {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectreporting}/fromproject/${this.projectId}/customerexcel?addAllWorklogsToReport=${loadFullReport}`, {
          responseType: 'blob' // Set the response type to 'blob'
        })

        const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '') // Format timestamp as YYYYMMDDHHmmss
        const filename = `Report_${timestamp}.xlsx`
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        link.target = '_blank'

        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async LoadInternalExcelReport () {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectreporting}/fromproject/${this.projectId}/completeexcel`, {
          responseType: 'blob' // Set the response type to 'blob'
        })

        const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '') // Format timestamp as YYYYMMDDHHmmss
        const filename = `Report_${timestamp}.xlsx`
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        link.target = '_blank'

        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    const urlParts = window.location.pathname.split('/')
    const projectId = urlParts[urlParts.length - 2] // Extract the second-to-last part from the URL path
    this.projectId = projectId
    this.loadReport()
    // this.loadCostceiling()
  }
}
</script>

<style lang="scss">
.fw-bold {
    font-weight: bold;
    font-size: 1.0rem;
   }
   .summaryrow {
    font-size:1.0rem;
   }

   .bigbutton {
    font-size: 1.1rem;
   }
</style>
