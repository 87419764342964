<template>
   <div>
      <h2>Abzurechnende Stunden</h2>
      <div v-if="loading" class="loading-overlay">
         <div class="loading-spinner"></div>
      </div>
      <b-row class="mb-3" align-h="between">
         <b-col cols="auto"></b-col>
         <b-col cols="auto">
            <!-- <b-button variant="outline-primary" @click="navigate('Users.Addresses.Add')"><b-icon icon="plus"/> {{ $t('views.users.addresses.button.addaddress') }}</b-button> -->
         </b-col>
      </b-row>
      <div>
         <div v-if="totalHoursPrepared > 0">
            <h3>Übersicht - An Bexio senden</h3>
            <p  class="alert alert-primary" style="font-size:1.2rem">Total "an Bexio senden": {{ formatNumber(totalHoursPrepared) }} Stunden resp. {{ formatMoney(totalMoneyPrepared) }} CHF </p>
            <div v-if="!sendingToBexio">
               <b-button v-if="!sentToBexio" variant="outline-primary" @click="sendToBexio">
                  <b-icon icon="plus" />
                  Jetzt an Bexio übermitteln
               </b-button>
               <p class="alert alert-success" v-else>Die Stunden wurden an Bexio übermittelt.</p>
            </div>
            <div v-else>
               <div class="loading-spinner"></div>
            </div>
            <p>Nachfolgende Einträge sind vorbereitet, um an Bexio gesendet zu werden.</p>
            <div v-if="this.hasCostCeiling">
               <h4>Teil von einem Kostendach</h4>
               <b-table striped hover :items="this.summaryreports.withCostCeiling" :fields="summaryreportsfields">
                  <template v-slot:cell(start)="data">
                     <timeago :datetime="data.item.start" auto-update/>
                     <!-- <b-form-input v-model="data.item.start" @blur="updateWorklog(data.item)" /> -->
                  </template>
                  <template v-slot:cell(importToBexio)="data">
                     <b-form-checkbox v-model="data.item.importToBexio" @change="updateWorklog(data.item)" />
                  </template>
                  <template v-slot:cell(isIgnoredForAccounting)="data">
                     <b-form-checkbox v-model="data.item.isIgnoredForAccounting" @change="updateWorklog(data.item)" />
                  </template>
                  <template v-slot:cell(clickUpCustomTaskId)="data">
                     <b-form-input v-model="data.item.clickUpCustomTaskId" @blur="updateWorklog(data.item)" />
                  </template>
                  <template v-slot:cell(comment)="data">
                     <b-form-input v-model="data.item.comment" @blur="updateWorklog(data.item)" />
                  </template>
                  <template v-slot:cell(taskName)="data">
                     <b-form-input v-model="data.item.taskName" @blur="updateWorklog(data.item)" />
                  </template>
                  <template v-slot:cell(hoursPrepared)="data">
                     {{data.item.hoursPrepared}}<br /><small> ({{ formatHoursToDays(data.item.hoursPrepared)}})</small>
                  </template>
                  <template v-slot:cell(projectAccountingTaskId)="data">
                     <b-form-select v-model="data.item.projectAccountingTaskId" :options="accountingTaskOptions" @change="updateWorklog(data.item)" />
                  </template>
               </b-table>
               <br />
            </div>
            <h4 v-if="this.hasCostCeiling">Leistungen ausserhalb Kostendach</h4>
            <h4 v-else>Leistungen ohne Kostendach</h4>
            <b-table striped hover :items="this.summaryreports.withoutCostCeiling" :fields="summaryreportsfields">
               <template v-slot:cell(start)="data">
                  <span>{{ formatDate(data.item.start) }}</span>
                  <!-- <b-form-input v-model="data.item.start" @blur="updateWorklog(data.item)" /> -->
               </template>
               <template v-slot:cell(importToBexio)="data">
                  <b-form-checkbox v-model="data.item.importToBexio" @change="updateWorklog(data.item)" />
               </template>
               <template v-slot:cell(isIgnoredForAccounting)="data">
                  <b-form-checkbox v-model="data.item.isIgnoredForAccounting" @change="updateWorklog(data.item)" />
               </template>
               <template v-slot:cell(clickUpCustomTaskId)="data">
                  <b-form-input v-model="data.item.clickUpCustomTaskId" @blur="updateWorklog(data.item)" />
               </template>
               <template v-slot:cell(comment)="data">
                  <b-form-input v-model="data.item.comment" @blur="updateWorklog(data.item)" />
               </template>
               <template v-slot:cell(taskName)="data">
                  <b-form-input v-model="data.item.taskName" @blur="updateWorklog(data.item)" />
               </template>
               <template v-slot:cell(hoursPrepared)="data">
                  {{data.item.hoursPrepared}}<small> ({{ formatHoursToDays(data.item.hoursPrepared)}})</small>
               </template>
               <template v-slot:cell(projectAccountingTaskId)="data">
                  <b-form-select v-model="data.item.projectAccountingTaskId" :options="accountingTaskOptions" @change="updateWorklog(data.item)" />
               </template>
            </b-table>
         </div>
      </div>
      <br />
      <div v-for="entry in this.reports" :key="entry.projectAccountingTask.id">
         <hr class="hr" />
         <b-row class="mb-3" align-h="between">
            <b-col cols="auto">
              <ol class="list-group list-group-numbered">
                 <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <h2>{{entry.accountingTask.name}}</h2>
                  </div>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Total rapportiert</div>
                    So viel Zeit ist auf diesem Task insgesamt rapportiert.
                  </div>
                  <span class="badge bg-secondary summaryrow">{{formatNumber(entry.projectTaskReport.totalReported)}}</span>
                </li>
                <li v-if="entry.projectTaskReport.totalReadyForImport > 0" class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div v-if="!entry.canBeSentToBexio" class="alert alert-danger">ACHTUNG DATENFEHLER.</div>
                    <div class="fw-bold">Total An Bexio senden</div>
                    Total an Stunden im Zustand "An Bexio senden"
                  </div>
                  <span class="badge bg-warning summaryrow">{{formatNumber(entry.projectTaskReport.totalReadyForImport)}}</span>
                </li>
                 <li v-if="entry.canBeSentToBexio && entry.projectTaskReport.totalToBePrepared !== undefined && entry.projectTaskReport.totalToBePrepared > 0" class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Total Gebucht und abrechenbar</div>
                    Diese Stunden sind bereits Gebucht und noch abrechenbar
                  </div>
                  <span class="badge bg-primary summaryrow">{{formatNumber(entry.projectTaskReport.totalToBePrepared)}}</span>
                </li>
                <template v-if="entry.projectTaskReport.hasCostCeiling && entry.canBeSentToBexio && entry.worklogs.costCeiling !== undefined && entry.worklogs.costCeiling.length > 0">
                  <li class="bg-secondary list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Angaben Leistungen im Kostendach</div>
                  </div>
                </li>
                 <li v-if="entry.projectTaskReport.costCeiling.readyForImport > 0" class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">An Bexio senden <small>von Kostendach</small></div>
                    Total an Stunden im Zustand "An Bexio senden"
                  </div>
                  <span class="badge bg-warning summaryrow">{{formatNumber(entry.projectTaskReport.costCeiling.readyForImport)}}</span>
                </li>
                <li v-if="entry.projectTaskReport.costCeiling.toBePrepared !== undefined && entry.projectTaskReport.costCeiling.toBePrepared > 0" class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Gebucht und abrechenbar <small>von Kostendach</small></div>
                    Diese Stunden sind bereits Gebucht unter dem Kostendach noch abrechenbar
                  </div>
                  <span class="badge bg-primary summaryrow">{{formatNumber(entry.projectTaskReport.costCeiling.toBePrepared)}}</span>
                </li>
                <li v-if="entry.projectTaskReport.costCeiling.remainingFromBudget !== undefined" class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Total noch übrige Stunden in Kostendach</div>
                    Total ist noch diese Anzahl Stunden unter dem Kostendach abrechenbar
                  </div>
                  <span class="badge bg-primary summaryrow" v-if="entry.projectTaskReport.costCeiling.remainingFromBudget > 0">{{formatNumber(entry.projectTaskReport.costCeiling.remainingFromBudget)}}</span>
                  <span class="badge bg-danger summaryrow" v-else-if="entry.projectTaskReport.costCeiling.remainingFromBudget < 0">{{formatNumber(entry.projectTaskReport.costCeiling.remainingFromBudget)}}</span>
                  <span class="badge bg-secondary summaryrow" v-else>{{formatNumber(entry.projectTaskReport.costCeiling.remainingFromBudget)}}</span>
                </li>
                <li v-if="entry.projectTaskReport.costCeiling.overBudget !== undefined" class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Überschossen <small>von Kostendach</small></div>
                    Diese Stunden sind unter dem Kostendach noch abrechenbar
                  </div>
                  <span class="badge bg-danger summaryrow">{{formatNumber(entry.projectTaskReport.costCeiling.overBudget)}}</span>
                </li>
                </template>
                <template v-if="entry.canBeSentToBexio  && entry.worklogs.limitless !== undefined && entry.worklogs.limitless.length > 0">
                  <li class="bg-secondary list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Angaben Leistungen ausserhalb Kostendach</div>
                  </div>
                </li>
                 <li v-if="entry.projectTaskReport.limitless.readyForImport > 0" class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">An Bexio senden</div>
                    Total an Stunden im Zustand "An Bexio senden"
                  </div>
                  <span class="badge bg-warning summaryrow">{{formatNumber(entry.projectTaskReport.limitless.readyForImport)}}</span>
                </li>
                <li v-if="entry.projectTaskReport.limitless.toBePrepared !== undefined && entry.projectTaskReport.limitless.toBePrepared > 0" class="list-group-item d-flex justify-content-between align-items-start">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">Gebucht und abrechenbar</div>
                    Diese Stunden sind rapportiert und noch nicht verarbeitet
                  </div>
                  <span class="badge bg-primary summaryrow">{{formatNumber(entry.projectTaskReport.limitless.toBePrepared)}}</span>
                </li>
                </template>
              </ol>
            </b-col>
         </b-row>
         <div>
         </div>
         <div v-if="entry.canBeSentToBexio">
            <div v-if="hasCostCeiling">
              <b-tabs>
               <b-tab title="Teil von Kostendach" v-if="entry.worklogs.costCeiling !== undefined && entry.worklogs.costCeiling.length > 0">
                  <b-form-input v-model="entry.search" placeholder="Einträge Filtern..."></b-form-input>
                  <b-table striped hover :items="filterItems(entry.worklogs.costCeiling,entry.search)" :fields="fields" v-if="canBePrepared(entry)">
                     <template v-slot:cell(start)="data">
                        <span>{{ formatDate(data.item.start) }}</span>
                     </template>
                     <template v-slot:cell(importToBexio)="data">
                        <b-form-checkbox v-if="entry.canBeSentToBexio" v-model="data.item.importToBexio" @change="updateWorklog(data.item)" />
                        <span v-else>-</span>
                     </template>
                     <template v-slot:cell(isIgnoredForAccounting)="data">
                        <b-form-checkbox v-model="data.item.isIgnoredForAccounting" @change="updateWorklog(data.item)" />
                     </template>
                     <template v-slot:cell(isIgnoredForCostCeiling)="data">
                        <template v-if="hasCostCeiling">
                           <b-form-checkbox  v-if="entry.canBeSentToBexio"  v-model="data.item.isIgnoredForCostCeiling" @change="updateWorklog(data.item)" />
                           <span v-else>-</span>
                        </template>
                     </template>
                     <template v-slot:cell(clickUpCustomTaskId)="data">
                        <b-form-input v-model="data.item.clickUpCustomTaskId" @blur="updateWorklog(data.item)" />
                        <div v-if="data.item.originalValues && data.item.originalValues.clickUpCustomTaskId != data.item.clickUpCustomTaskId" class="original">Original: {{data.item.originalValues.clickUpCustomTaskId}}</div>
                     </template>
                     <template v-slot:cell(comment)="data">
                        <b-form-textarea v-model="data.item.comment" @blur="updateWorklog(data.item)" />
                        <div v-if="data.item.originalValues && data.item.originalValues.comment != data.item.comment" class="original">Original: {{data.item.originalValues.comment}}</div>
                     </template>
                     <template v-slot:cell(taskName)="data">
                        <b-form-textarea v-model="data.item.taskName" @blur="updateWorklog(data.item)" />
                        <div v-if="data.item.originalValues && data.item.originalValues.taskName != data.item.taskName" class="original">Original: {{data.item.originalValues.taskName}}</div>
                     </template>
                     <template v-slot:cell(industryTimeSpend)="data">
                        <b-form-input v-model="data.item.industryTimeSpend" @blur="updateWorklog(data.item)" />
                        <small>{{ formatMinutesToHoursMinutes(data.item.timeSpendInMinutes)}}</small>
                        <div v-if="data.item.originalValues && data.item.originalValues.timeSpendInMinutes != data.item.timeSpendInMinutes" class="original">Original: {{data.item.originalValues.timeSpendInMinutes}}</div>
                     </template>
                     <template v-slot:cell(projectAccountingTaskId)="data">
                        <b-form-select v-model="data.item.projectAccountingTaskId" :options="accountingTaskOptions" @change="updateWorklog(data.item)" />
                     </template>
                     <template v-slot:cell(parentTaskId)="data">
                           <span style="font-size:10px">{{data.item.parentTaskId}} <br />{{data.item.parentTaskName}}</span>
                     </template>
                  </b-table>
               </b-tab>
               <b-tab title="Nicht Teil von Kostendach" v-if="entry.worklogs.limitless !== undefined && entry.worklogs.limitless.length > 0">
                  <b-form-input v-model="entry.search" placeholder="Einträge Filtern..."></b-form-input>
                  <b-table striped hover :items="filterItems(entry.worklogs.limitless,entry.search)" :fields="fields" v-if="canBePrepared(entry)">
                     <template v-slot:cell(start)="data">
                        <span>{{ formatDate(data.item.start) }}</span>
                        <!-- <b-form-input v-model="data.item.start" @blur="updateWorklog(data.item)" /> -->
                     </template>
                     <template v-slot:cell(importToBexio)="data">
                        <b-form-checkbox v-if="entry.canBeSentToBexio" v-model="data.item.importToBexio" @change="updateWorklog(data.item)" />
                        <span v-else>-</span>
                     </template>
                     <template v-slot:cell(isIgnoredForAccounting)="data">
                        <b-form-checkbox v-model="data.item.isIgnoredForAccounting" @change="updateWorklog(data.item)" />
                     </template>
                     <template v-slot:cell(isIgnoredForCostCeiling)="data">
                        <template v-if="hasCostCeiling">
                           <b-form-checkbox  v-if="entry.canBeSentToBexio"  v-model="data.item.isIgnoredForCostCeiling" @change="updateWorklog(data.item)" />
                           <span v-else>-</span>
                        </template>
                     </template>
                     <template v-slot:cell(clickUpCustomTaskId)="data">
                        <b-form-input v-model="data.item.clickUpCustomTaskId" @blur="updateWorklog(data.item)" />
                        <div v-if="data.item.originalValues && data.item.originalValues.clickUpCustomTaskId != data.item.clickUpCustomTaskId" class="original">Original: {{data.item.originalValues.clickUpCustomTaskId}}</div>
                     </template>
                     <template v-slot:cell(comment)="data">
                        <b-form-textarea v-model="data.item.comment" @blur="updateWorklog(data.item)" />
                        <div v-if="data.item.originalValues && data.item.originalValues.comment != data.item.comment" class="original">Original: {{data.item.originalValues.comment}}</div>
                     </template>
                     <template v-slot:cell(taskName)="data">
                        <b-form-textarea v-model="data.item.taskName" @blur="updateWorklog(data.item)" />
                        <div v-if="data.item.originalValues && data.item.originalValues.taskName != data.item.taskName" class="original">Original: {{data.item.originalValues.taskName}}</div>
                     </template>
                     <template v-slot:cell(industryTimeSpend)="data">
                        <b-form-input v-model="data.item.industryTimeSpend" @blur="updateWorklog(data.item)" />
                        <small>{{ formatMinutesToHoursMinutes(data.item.timeSpendInMinutes)}}</small>
                        <div v-if="data.item.originalValues && data.item.originalValues.timeSpendInMinutes != data.item.timeSpendInMinutes" class="original">Original: {{data.item.originalValues.timeSpendInMinutes}}</div>
                     </template>
                     <template v-slot:cell(projectAccountingTaskId)="data">
                        <b-form-select v-model="data.item.projectAccountingTaskId" :options="accountingTaskOptions" @change="updateWorklog(data.item)" />
                     </template>
                     <template v-slot:cell(parentTaskId)="data">
                           <span style="font-size:10px">{{data.item.parentTaskId}} <br />{{data.item.parentTaskName}}</span>
                     </template>
                  </b-table>
               </b-tab>
            </b-tabs>
            </div>
            <div v-else>
                  <b-form-input v-model="entry.search" placeholder="Einträge Filtern..."></b-form-input>
                  <b-table striped hover :items="filterItems(entry.worklogs.limitless,entry.search)" :fields="fieldsWithoutCostCeiling" v-if="canBePrepared(entry)">
                     <template v-slot:cell(start)="data">
                        <span>{{ formatDate(data.item.start) }}</span>
                        <!-- <b-form-input v-model="data.item.start" @blur="updateWorklog(data.item)" /> -->
                     </template>
                     <template v-slot:cell(importToBexio)="data">
                        <b-form-checkbox v-if="entry.canBeSentToBexio" v-model="data.item.importToBexio" @change="updateWorklog(data.item)" />
                        <span v-else>-</span>
                     </template>
                     <template v-slot:cell(isIgnoredForAccounting)="data">
                        <b-form-checkbox v-model="data.item.isIgnoredForAccounting" @change="updateWorklog(data.item)" />
                     </template>
                     <template v-slot:cell(isIgnoredForCostCeiling)="data">
                        <template v-if="hasCostCeiling">
                           <b-form-checkbox  v-if="entry.canBeSentToBexio"  v-model="data.item.isIgnoredForCostCeiling" @change="updateWorklog(data.item)" />
                           <span v-else>-</span>
                        </template>
                     </template>
                     <template v-slot:cell(clickUpCustomTaskId)="data">
                        <b-form-input v-model="data.item.clickUpCustomTaskId" @blur="updateWorklog(data.item)" />
                        <div v-if="data.item.originalValues && data.item.originalValues.clickUpCustomTaskId != data.item.clickUpCustomTaskId" class="original">Original: {{data.item.originalValues.clickUpCustomTaskId}}</div>
                     </template>
                     <template v-slot:cell(comment)="data">
                        <b-form-textarea v-model="data.item.comment" @blur="updateWorklog(data.item)" />
                        <div v-if="data.item.originalValues && data.item.originalValues.comment && data.item.originalValues.comment != data.item.comment" class="original">Original: {{data.item.originalValues.comment}}</div>
                     </template>
                     <template v-slot:cell(taskName)="data">
                        <b-form-textarea v-model="data.item.taskName" @blur="updateWorklog(data.item)" />
                        <div v-if="data.item.originalValues && data.item.originalValues.taskName != data.item.taskName" class="original">Original: {{data.item.originalValues.taskName}}</div>
                     </template>
                     <template v-slot:cell(industryTimeSpend)="data">
                        <b-form-input v-model="data.item.industryTimeSpend" @blur="updateWorklog(data.item)" />
                        <small>{{ formatMinutesToHoursMinutes(data.item.timeSpendInMinutes)}}</small>
                        <div v-if="data.item.originalValues && data.item.originalValues.timeSpendInMinutes != data.item.timeSpendInMinutes" class="original">Original: {{data.item.originalValues.timeSpendInMinutes}}</div>
                     </template>
                     <template v-slot:cell(parentTaskId)="data">
                           <span style="font-size:10px">{{data.item.parentTaskId}} <br />{{data.item.parentTaskName}}</span>
                     </template>
                     <template v-slot:cell(projectAccountingTaskId)="data">
                        <b-form-select v-model="data.item.projectAccountingTaskId" :options="accountingTaskOptions" @change="updateWorklog(data.item)" />
                     </template>
                  </b-table>
            </div>
         </div>
         <div v-else>
            <b-form-input v-model="entry.search" placeholder="Einträge Filtern..."></b-form-input>
            <b-table striped hover :items="filterItems(entry.worklogs.notAccountable,entry.search)" :fields="fieldsnotassigned">
               <template v-slot:cell(start)="data">
                  <span>{{ formatDate(data.item.start) }}</span>
                  <!-- <timeago :datetime="data.item.start" auto-update/> -->
                  <!-- <b-form-input v-model="data.item.start" @blur="updateWorklog(data.item)" /> -->
               </template>
               <template v-slot:cell(importToBexio)="data">
                  <b-form-checkbox v-model="data.item.importToBexio" disabled />
               </template>
               <template v-slot:cell(isIgnoredForAccounting)="data">
                  <b-form-checkbox v-model="data.item.isIgnoredForAccounting" disabled />
               </template>
               <template v-slot:cell(clickUpCustomTaskId)="data">
                  {{ data.item.clickUpCustomTaskId}}
               </template>
               <template v-slot:cell(comment)="data">
                  {{data.item.comment}}
               </template>
               <template v-slot:cell(taskName)="data">
                  {{data.item.taskName}}
               </template>
               <template v-slot:cell(industryTimeSpend)="data">
                  <small>{{ formatMinutesToHoursMinutes(data.item.timeSpendInMinutes)}}</small>
               </template>
               <template v-slot:cell(projectAccountingTaskId)="data">
                    <b-form-select v-if="worklogTaskCanBeChanged(entry)" v-model="data.item.projectAccountingTaskId" :options="accountingTaskOptions" @change="updateWorklog(data.item)" />
                </template>
            </b-table>
         </div>
      </div>
   </div>
</template>
<script>
import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

// import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
// import EntityEntryTable from '@/components/entity/EntryTable'
import http from '@/$plugins/http'

const ENTITY_KEY = 'projectaccountingtasks'
const RELATION_KEY = 'accountingtasks'
const WORKLOGS_NOT_EDITABLE = 'Nicht zugeordnet'

export default {
  name: 'Projects.WorklogsReadyToPrepare',
  mixins: [MixinEntityBase],
  components: {
    // EntityEntryTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      worklogsNotEditable: WORKLOGS_NOT_EDITABLE,
      hasCostCeiling: false,
      projectaccountingtasks: [],
      accountingtasks: [],
      loading: false,
      reports: [],
      summaryreports: [],
      emailFilter: '',
      accountableFilter: false,
      dateFilter: null,
      accountingTaskFilter: '',
      isSelectedFilter: false,
      sendingToBexio: false,
      sentToBexio: false,
      fields: [
        { key: 'importToBexio', label: 'An Bexio senden', sortable: true },
        { key: 'isIgnoredForCostCeiling', label: 'Nicht Teil von Kostendach', sortable: true },
        { key: 'isIgnoredForAccounting', label: 'Für Abrechnung Ignorrieren', sortable: true },
        { key: 'projectAccountingTaskId', label: 'Abrechnungs-Task', sortable: true },
        { key: 'username', label: 'Benutzer', sortable: true },
        { key: 'clickUpCustomTaskId', label: 'Task-Id', sortable: true },
        { key: 'taskName', label: 'Task-Name', sortable: true },
        { key: 'comment', label: 'Kommentar', sortable: true },
        { key: 'parentTaskId', label: 'Haupt-Task', sortable: true },
        { key: 'start', label: 'Datum', sortable: true },
        { key: 'industryTimeSpend', label: 'Zeitaufwand', sortable: true }
      ],
      fieldsnotassigned: [
        { key: 'username', label: 'Benutzer', sortable: true },
        { key: 'parentTaskName', label: 'Parent Task-Id', sortable: true },
        { key: 'clickUpCustomTaskId', label: 'Task-Id', sortable: true },
        { key: 'projectAccountingTaskId', label: 'Abrechnungs-Task', sortable: true },
        { key: 'taskName', label: 'Task-Name', sortable: true },
        { key: 'comment', label: 'Kommentar', sortable: true },
        { key: 'start', label: 'Datum', sortable: true },
        { key: 'industryTimeSpend', label: 'Zeitaufwand', sortable: true }
      ],
      fieldsWithoutCostCeiling: [
        { key: 'importToBexio', label: 'An Bexio senden', sortable: true },
        { key: 'isIgnoredForAccounting', label: 'Für Abrechnung Ignorrieren', sortable: true },
        { key: 'projectAccountingTaskId', label: 'Abrechnungs-Task', sortable: true },
        { key: 'username', label: 'Benutzer', sortable: true },
        { key: 'clickUpCustomTaskId', label: 'Task-Id', sortable: true },
        { key: 'taskName', label: 'Task-Name', sortable: true },
        { key: 'comment', label: 'Kommentar', sortable: true },
        { key: 'parentTaskId', label: 'Haupt-Task', sortable: true },
        { key: 'start', label: 'Datum', sortable: true },
        { key: 'industryTimeSpend', label: 'Zeitaufwand', sortable: true }
      ],
      summaryreportsfields: [
        { key: 'accountingTaskName', label: 'Taskname', sortable: true },
        { key: 'hoursPrepared', label: 'Stunden', sortable: true, formatter: (value, key, item) => this.formatNumber(value), class: 'text-right' },
        { key: 'hourlyRate', label: 'Stundensatz', sortable: true, formatter: (value, key, item) => this.formatMoney(value), class: 'text-right' },
        { key: 'moneyPrepared', label: 'Total', sortable: true, formatter: (value, key, item) => this.formatMoney(value), class: 'text-right' }
      ]
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    },
    totalHoursPrepared () {
      let total = 0
      this.summaryreports.withCostCeiling.forEach(item => {
        total += item.hoursPrepared
      })
      this.summaryreports.withoutCostCeiling.forEach(item => {
        total += item.hoursPrepared
      })
      return total
    },
    totalMoneyPrepared () {
      let total = 0
      this.summaryreports.withCostCeiling.forEach(item => {
        total += item.moneyPrepared
      })
      this.summaryreports.withoutCostCeiling.forEach(item => {
        total += item.moneyPrepared
      })
      return total
    },
    accountingTaskOptions () {
      return this.projectaccountingtasks.map(task => ({
        text: task.accountingTask.name,
        value: task.id
      })).sort((a, b) => a.text.localeCompare(b.text))
    }
  },
  methods: {
    filterItems (items, search) {
      if (!search) {
        return items
      }
      const lowerSearch = search.toLowerCase()
      return items.filter(item =>
        Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(lowerSearch)
        )
      )
    },
    canBePrepared (entry) {
      return entry.accountingTask.name !== this.worklogsNotEditable
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('de-CH', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      })
    },
    worklogTaskCanBeChanged (entry) {
      return entry.accountingTask.name !== this.worklogsNotEditable
    },
    formatMinutesToHoursMinutes (minutes) {
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60
      return `${hours}h ${remainingMinutes}m`
    },
    formatHoursToDays (value) {
      const days = Math.floor(value / 24)
      const hours = value % 24
      return `${days}d ${hours}h`
    },
    formatNumber (number) {
      if (number === null || number === undefined) return
      return number.toLocaleString('de-CH', { minimumFractionDigits: 2 })
    },
    formatMoney (amount) {
      return parseFloat(amount).toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF'
      })
    },
    filteredWorklogs (worklogs, value) {
      return worklogs.filter((worklog) => worklog.isIgnoredForCostCeiling === value)
    },
    selectAccountingTask (projectAccountingTaskId) {
      const selectedAccountingTaskId = this.projectaccountingtasks.filter(at => at.id === projectAccountingTaskId)[0].accountingTaskId
      return selectedAccountingTaskId
    },
    async updateWorklog (worklog) {
      try {
        const timeSpendInMinutes = Math.round(worklog.industryTimeSpend * 60)
        const endpoint = `/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.worklogs}/${worklog.id}/updatepartial`
        const payload = {
          clickUpCustomTaskId: worklog.clickUpCustomTaskId,
          taskName: worklog.taskName,
          comment: worklog.comment,
          timeSpendInMinutes: timeSpendInMinutes,
          isIgnoredForAccounting: worklog.isIgnoredForAccounting,
          isIgnoredForCostCeiling: worklog.isIgnoredForCostCeiling,
          importToBexio: worklog.importToBexio,
          projectAccountingTaskId: worklog.projectAccountingTaskId
        }
        await http.put(endpoint, payload)
        // Handle the response or perform additional actions if needed
      } catch (error) {
        console.error(error)
      }
    },
    async loadWorklogs () {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectreporting}/fromproject/${this.projectId}/tobeprepared`)
        const responsePreparedReport = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectreporting}/fromproject/${this.projectId}/prepared`)
        const responseCostCeiling = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectreporting}/fromproject/${this.projectId}/hascostceiling`)
        const projectaccountingtaskResponse = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectaccountingtasks}/fromproject/${this.projectId}/all`)
        this.projectaccountingtasks = projectaccountingtaskResponse.data.result

        const accountingtaskResponse = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.accountingtasks}/all`)
        this.accountingtasks = accountingtaskResponse.data.result

        this.projectaccountingtasks.forEach(entry => {
          entry.accountingTask = this.accountingtasks.filter(at => at.id === entry.accountingTaskId)[0]
        })

        this.hasCostCeiling = responseCostCeiling.data
        this.reports = response.data
        this.summaryreports = responsePreparedReport.data

        this.reports.forEach(entry => {
          entry.worklogs.limitless.forEach(worklog => {
            const hours = Math.floor(worklog.timeSpendInMinutes / 60)
            const minutes = worklog.timeSpendInMinutes % 60
            worklog.industryTimeSpend = hours + minutes / 60
          })

          entry.worklogs.costCeiling.forEach(worklog => {
            const hours = Math.floor(worklog.timeSpendInMinutes / 60)
            const minutes = worklog.timeSpendInMinutes % 60
            worklog.industryTimeSpend = hours + minutes / 60
          })
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    setReadyToUpload (worklog, value) {
      worklog.isReadyToUpload = value
      // Here, you would probably also want to send a request to your API to update the worklog item on the server
    },
    async sendToBexio () {
      try {
        this.sendingToBexio = true
        await http.put(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.worklogs}/fromproject/${this.projectId}/sendtobexio`) // Replace with the actual endpoint
        // Handle the successful response if needed
      } catch (error) {
        console.error(error)
        // Handle the error if needed
      } finally {
        this.sendingToBexio = false
        this.sentToBexio = true
      }
    }
  },
  created () {
    const urlParams = new URLSearchParams(window.location.search)
    this.currentPage = parseInt(urlParams.get('page')) || 1
    this.pageSize = parseInt(urlParams.get('pageSize')) || 50
    const urlParts = window.location.pathname.split('/')
    const projectId = urlParts[urlParts.length - 2] // Extract the second-to-last part from the URL path
    this.projectId = projectId
    this.loadWorklogs()
  }
}
</script>
<style lang="scss">
   .loading-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   }
   .loading-spinner {
   border: 4px solid #f3f3f3;
   border-top: 4px solid #3498db;
   border-radius: 50%;
   width: 30px;
   height: 30px;
   animation: spin 1s linear infinite;
   }
   .fw-bold {
    font-weight: bold;
    font-size: 1.0rem;
   }
   .summaryrow {
    font-size:1.0rem;
   }
   @keyframes spin {
   0% {
   transform: rotate(0deg);
   }
   100% {
   transform: rotate(360deg);
   }
   }
   .original {
      padding-top: 2px;
    font-size: 0.8rem;
    color: #999;
   }
</style>
