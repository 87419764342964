<template>
  <div>
    <!-- if this is active route (e.g. /users) -->
    <template v-if="isCurrentRoute">
      <h2>Nicht zugeordnet in Clickup</h2>
      <p>Die nachfolgenden Einträge sind in Clickup noch keiner korrektem Abrechnungstask zugeordnet</p>

      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
        </b-col>
      </b-row>

    <b-table striped hover :items="worklogs"
      :fields="fields"
      :per-page="pageSize"
      :current-page="currentPage">
      <template v-slot:cell(sentToBexioAt)="data">
        <timeago :datetime="data.item.sentToBexioAt"/>
      </template>
      <template v-slot:cell(start)="data">
        <timeago :datetime="data.item.start"/>
      </template>
  <template v-slot:cell(clickUpCustomTaskId)="data">
    <span>{{data.item.clickUpCustomTaskId}}</span>
  </template>
  <template v-slot:cell(comment)="data">
    <span>{{data.item.comment}}</span>
  </template>
  <template v-slot:cell(taskName)="data">
    <span>{{data.item.taskName}}</span>
  </template>
  <template v-slot:cell(timeSpendInMinutes)="data">
    <p>{{ formatMinutesToHoursMinutes(data.item.timeSpendInMinutes)}}</p>
  </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :currentPage="currentPage"
      :total-rows="totalItems"
      :per-page="pageSize"
      align="center"
      size="md"
      class="my-3"
      @input="loadWorklogs"
    ></b-pagination>
    </template>

    <!-- if subroute is active route (e.g. /accountingtasks/detail) -->
    <section v-else>
      <router-view/>
    </section>
  </div>
</template>

<script>
import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

import MixinEntityBase from '@/components/mixins/EntityBase'
import http from '@/$plugins/http'
import { BPagination } from 'bootstrap-vue'

const ENTITY_KEY = 'projectaccountingtasks'
const RELATION_KEY = 'accountingtasks'
const RELEVANT_ACCOUNTING_TASK_NAME = 'Nicht zugeordnet'

export default {
  name: 'Projects.WorklogsWithClickUpNotAssigned',
  mixins: [MixinEntityBase],
  components: {
    BPagination
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      relevantAccountingTaskName: RELEVANT_ACCOUNTING_TASK_NAME,
      loading: false,
      worklogs: [],
      emailFilter: '',
      accountableFilter: false,
      dateFilter: null,
      accountingTaskFilter: '',
      isSelectedFilter: false,
      currentPage: 1, // current page number
      perPage: 10, // number of items per page
      totalItems: 0,
      tableKey: 0,
      fields: [
        { key: 'clickUpCustomTaskId', label: 'Task-Id', sortable: true },
        { key: 'taskName', label: 'Task-Name', sortable: true },
        { key: 'comment', label: 'Kommentar', sortable: true },
        { key: 'isDeleted', label: 'Is Deleted', sortable: true },
        { key: 'username', label: 'Benutzer', sortable: true },
        { key: 'start', label: 'Datum', sortable: true },
        { key: 'timeSpendInMinutes', label: 'Zeitaufwand', sortable: true }
      ]
    }
  },
  computed: {
    isCurrentRoute () {
      return () => Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    }
  },
  methods: {
    formatMinutesToHoursMinutes (minutes) {
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60
      return `${hours}h ${remainingMinutes}m`
    },
    async loadWorklogs () {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.worklogs}/fromproject/${this.projectId}/unassigned`, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize
          }
        })
        this.worklogs = response.data.result
        this.totalItems = response.data.totalItems
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.loadWorklogs()
  },
  created () {
    const urlParams = new URLSearchParams(window.location.search)
    this.currentPage = parseInt(urlParams.get('page')) || 1
    this.pageSize = parseInt(urlParams.get('pageSize')) || 50
    const urlParts = window.location.pathname.split('/')
    const projectId = urlParts[urlParts.length - 2] // Extract the second-to-last part from the URL path
    this.projectId = projectId

    this.loadWorklogs()
  },
  watch: {
    worklogs: {
      deep: true,
      handler (newValue, oldValue) {
        this.worklogs = newValue
      }
    }
  // ...
  }
}
</script>

<style lang="scss"></style>
